import { Slot } from '@radix-ui/react-slot';
import * as RadioPrimitives from './primitives';
/**
 * Radio 컴포넌트 속성
 */
export interface RadioProps extends RadioPrimitives.RootProps {
  type?: string;
  items: RadioPrimitives.ItemProps[];
}

/**
 * Radio 컴포넌트
 * @returns
 */
export function Radio({ items, type, ...restprops }: RadioProps) {
  const Comp = type === 'div' ? 'div' : Slot;

  return (
    <RadioPrimitives.Root {...restprops}>
      {items.map((item) => (
        <Comp key={item.id}>
          <>
            <RadioPrimitives.Item key={item.id} {...item} />
            <label htmlFor={item.id}>
              {item.label ? item.label : item.value}
            </label>
          </>
        </Comp>
      ))}
    </RadioPrimitives.Root>
  );
}

export default Radio;
