import clsx from 'clsx';
import type { CommonProps } from './types';

enum PredefinedSize {
  Small = 20,
  Medium = 26,
  Large = 32,
}

/**
 * DaouofficeLogo 컴포넌트
 * @returns
 */
export function DaouofficeLogo({ color, size, className }: CommonProps) {
  const adaptedHeight =
    typeof size === 'number'
      ? size
      : size === 's' || size === 'small'
      ? PredefinedSize.Small
      : size === 'l' || size === 'large'
      ? PredefinedSize.Large
      : PredefinedSize.Medium;

  return (
    <svg
      viewBox="0 0 121 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx({ 'text-primary': color === 'primary' }, className)}
      height={adaptedHeight}
    >
      <path
        d="M46.2353 16.4756C44.9603 16.6474 43.6291 16.7806 42.2783 16.8677C40.9325 16.9548 39.6232 16.9766 38.4166 16.9814C38.2725 16.9814 38.2017 16.9451 38.2017 16.7515V8.31201H44.567V6.43872H36.123V17.1654C36.123 17.5454 36.2037 17.8092 36.3673 17.9713L37.0317 18.6297C37.1831 18.7797 37.4567 18.8547 37.8548 18.8547C38.1846 18.8668 38.5265 18.8741 38.8783 18.8741C39.23 18.8741 39.5915 18.8668 39.9603 18.8547C40.6931 18.8305 41.4381 18.7918 42.1782 18.741C42.9183 18.6902 43.651 18.6272 44.3594 18.5522C45.0653 18.4772 45.7468 18.3876 46.3843 18.286L46.5504 18.2593L46.4307 16.4466L46.2328 16.4732L46.2353 16.4756Z"
        fill="currentColor"
      />
      <path
        d="M49.3916 5.57227H47.2935V22.9208H49.3916V13.1501H52.0711V11.3132H49.3916V5.57227Z"
        fill="currentColor"
      />
      <path
        d="M56.1475 12.9856C56.6727 13.3486 57.3175 13.6221 58.0649 13.8036C58.8026 13.9827 59.6379 14.0723 60.549 14.0723C61.4601 14.0723 62.293 13.9779 63.0356 13.7939C63.783 13.6076 64.4278 13.3268 64.9505 12.9662C65.4732 12.6032 65.8885 12.1457 66.1767 11.6012C66.46 11.0614 66.6041 10.4322 66.6041 9.72787C66.6041 9.02357 66.46 8.4306 66.1767 7.89088C65.8909 7.35115 65.4806 6.8913 64.9603 6.51858C64.4449 6.1507 63.805 5.86753 63.0527 5.68116C62.3101 5.49722 61.4674 5.40283 60.549 5.40283C59.6306 5.40283 58.7855 5.49722 58.0429 5.68116C57.2931 5.86995 56.6482 6.1507 56.128 6.51858C55.5979 6.8913 55.19 7.35357 54.9091 7.8933C54.6307 8.43302 54.4915 9.05261 54.4915 9.72787C54.4915 10.4031 54.6356 11.0663 54.9213 11.6108C55.2047 12.1554 55.6175 12.6177 56.1475 12.9856ZM60.549 12.199C59.8969 12.199 59.3155 12.1312 58.8197 11.9981C58.3287 11.8698 57.911 11.6883 57.574 11.4632C57.2467 11.243 56.9951 10.9791 56.8265 10.6863C56.658 10.3886 56.575 10.0764 56.575 9.73029C56.575 9.01388 56.9023 8.4427 57.5691 7.98285C58.2506 7.51573 59.2545 7.27613 60.5515 7.27613C61.8485 7.27613 62.8377 7.51089 63.5241 7.97559C64.2007 8.4306 64.528 9.0042 64.528 9.73029C64.528 10.0667 64.4425 10.391 64.2764 10.6863C64.1054 10.9816 63.8538 11.2454 63.5265 11.4632C63.1894 11.6883 62.7669 11.8674 62.271 11.9981C61.7727 12.1312 61.1939 12.199 60.5539 12.199H60.549Z"
        fill="currentColor"
      />
      <path
        d="M52.3855 17.2354H59.5471V22.9207H61.6257V17.2354H68.7898V15.3984H52.3855V17.2354Z"
        fill="currentColor"
      />
      <path
        d="M78.6863 16.0327C79.419 15.9601 80.0883 15.8004 80.6818 15.5559C81.3218 15.2897 81.8689 14.9388 82.3086 14.5079C82.7482 14.0771 83.0926 13.5616 83.3296 12.9662C83.5641 12.3757 83.6837 11.7246 83.6837 11.0252C83.6837 10.2483 83.5372 9.54154 83.249 8.92437C82.9583 8.30478 82.5431 7.76506 82.013 7.32699C81.483 6.88408 80.8406 6.54524 80.1054 6.3129C79.3751 6.08297 78.547 5.9668 77.6457 5.9668C76.7444 5.9668 75.9139 6.08297 75.1763 6.3129C74.4362 6.54524 73.7962 6.87924 73.2686 7.31731C72.741 7.75538 72.3307 8.2951 72.0425 8.91469C71.7543 9.53912 71.6101 10.2483 71.6101 11.0252C71.6101 11.7101 71.7249 12.3539 71.9545 12.9372C72.1817 13.5181 72.5212 14.0384 72.9658 14.4789C73.403 14.9146 73.9501 15.2728 74.5925 15.5438C75.1836 15.798 75.8602 15.9601 76.6076 16.0327V18.9007H69.4827V20.774H85.8869V18.9007H78.6838V16.0327H78.6863ZM77.6482 7.84009C78.2979 7.84009 78.8743 7.92238 79.3653 8.08454C79.8514 8.24428 80.2666 8.47178 80.6037 8.75495C80.931 9.03329 81.1826 9.3697 81.3511 9.75695C81.5172 10.1442 81.6027 10.575 81.6027 11.0349C81.6027 11.4947 81.5172 11.9279 81.3511 12.3152C81.185 12.7024 80.9334 13.0388 80.6037 13.3172C80.2666 13.6003 79.8489 13.8279 79.3653 13.9876C78.8719 14.1497 78.2954 14.232 77.6482 14.232C77.0009 14.232 76.4171 14.1497 75.9188 13.9876C75.4254 13.8254 75.0102 13.6003 74.6804 13.3196C74.3556 13.0388 74.1089 12.7024 73.9404 12.3152C73.7743 11.9279 73.6888 11.4971 73.6888 11.0349C73.6888 10.5726 73.7743 10.1442 73.9404 9.75695C74.1064 9.3697 74.3556 9.03329 74.6804 8.75253C75.0078 8.47178 75.4254 8.2467 75.9188 8.08454C76.4171 7.92238 76.9984 7.84009 77.6482 7.84009Z"
        fill="currentColor"
      />
      <path
        d="M99.1456 16.4175C98.5497 16.5119 98.005 16.5845 97.5335 16.6305C97.1305 16.6692 96.6884 16.7031 96.1877 16.7273V8.31201H97.8584V6.43872H87.2284V8.31201H88.9748V16.7927H87.0378V18.666H92.8463C93.369 18.666 93.9234 18.6563 94.5145 18.6369C95.1056 18.6175 95.6943 18.5909 96.2781 18.5522C96.8618 18.5135 97.4261 18.4651 97.9512 18.4021C98.4935 18.3368 98.9478 18.269 99.341 18.194L99.5071 18.1625L99.3386 16.3861L99.1432 16.4175H99.1456ZM94.1066 8.31201V16.7927H91.0559V8.31201H94.1066Z"
        fill="currentColor"
      />
      <path
        d="M101.752 5.57227H99.6736V22.9184H101.752V5.57227Z"
        fill="currentColor"
      />
      <path
        d="M105.475 16.3038C107.016 15.8633 108.355 15.2703 109.452 14.5394C110.465 13.8665 111.283 13.0315 111.892 12.0538C112.512 12.9686 113.365 13.7867 114.432 14.4837C115.587 15.234 116.904 15.8415 118.347 16.2844L118.513 16.3352L119.239 14.4813L119.044 14.428C118.259 14.2054 117.488 13.9101 116.745 13.5446C116.012 13.1864 115.35 12.7629 114.779 12.2885C114.215 11.819 113.753 11.2962 113.413 10.7323C113.076 10.1805 112.905 9.58265 112.905 8.95338V6.66138H110.788V8.93402C110.788 9.56813 110.644 10.1708 110.363 10.725C110.077 11.2841 109.669 11.7996 109.151 12.2595C108.628 12.7217 107.988 13.1477 107.243 13.5229C106.496 13.898 105.661 14.2199 104.767 14.4789L104.569 14.537L105.316 16.3449L105.475 16.2989V16.3038Z"
        fill="currentColor"
      />
      <path
        d="M120.087 18.9006H103.682V20.7739H120.087V18.9006Z"
        fill="currentColor"
      />
      <path
        d="M10.6013 2.79128C10.6013 2.79128 10.5965 2.75013 10.5989 2.69205C10.6307 2.40403 11.0508 2.38467 11.0508 2.38467H13.2979C13.7791 2.38467 13.3541 1.94176 13.3541 1.94176C13.3541 1.94176 13.0854 1.71668 12.7655 1.45286C12.6946 1.37058 12.6091 1.28587 12.4894 1.18663C11.8495 0.661435 11.5295 0.44119 11.5295 0.44119C11.5295 0.44119 10.5867 -0.25585 9.56813 0.102351C8.54714 0.460552 3.91115 2.16201 3.91115 2.16201C3.91115 2.16201 2.84863 2.51053 2.84863 3.6771V14.9846H10.6013V2.79128Z"
        fill="currentColor"
      />
      <path
        d="M26.1216 9.48828C25.7626 8.4766 24.043 3.88534 24.043 3.88534C24.043 3.88534 23.6913 2.83252 22.514 2.83252H11.1047V10.5145H23.4079C23.4079 10.5145 23.4715 10.5072 23.5594 10.5145C23.811 10.575 23.8378 10.9501 23.8378 10.9501V13.1768C23.8378 13.6536 24.2848 13.23 24.2848 13.23C24.2848 13.23 24.5169 12.959 24.788 12.6371C24.8613 12.5693 24.9419 12.4894 25.0298 12.3829C25.5574 11.7488 25.7797 11.4318 25.7797 11.4318C25.7797 11.4318 26.4831 10.5 26.1216 9.48828Z"
        fill="currentColor"
      />
      <path
        d="M15.6167 23.2088C15.6167 23.2088 15.6241 23.2814 15.6167 23.3782C15.5508 23.625 15.1771 23.6517 15.1771 23.6517H12.9299C12.4487 23.6517 12.8762 24.0922 12.8762 24.0922C12.8762 24.0922 13.2352 24.3947 13.6212 24.7142C13.6578 24.748 13.6871 24.7795 13.7311 24.8134C14.3686 25.3386 14.691 25.5588 14.691 25.5588C14.691 25.5588 15.6338 26.2559 16.6524 25.8977C17.6758 25.5419 22.3069 23.8404 22.3069 23.8404C22.3069 23.8404 23.3719 23.4919 23.3719 22.3254V11.0178H15.6192V23.2088H15.6167Z"
        fill="currentColor"
      />
      <path
        d="M15.1226 15.4759H2.81699C2.81699 15.4759 2.74127 15.4832 2.64112 15.4759C2.39686 15.4057 2.37244 15.0427 2.37244 15.0427V12.816C2.37244 12.3392 1.92545 12.7628 1.92545 12.7628C1.92545 12.7628 1.62501 13.1137 1.30748 13.4913C1.27084 13.53 1.23664 13.5639 1.19512 13.6099C0.667526 14.244 0.445253 14.561 0.445253 14.561C0.445253 14.561 -0.258206 15.4928 0.103294 16.5045C0.462351 17.5162 2.18192 22.105 2.18192 22.105C2.18192 22.105 2.53365 23.1603 3.70852 23.1603H15.1226V15.4783V15.4759Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default DaouofficeLogo;
