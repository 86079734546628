export const DEFAULT_SIZE = 88;
export const DEFAULT_BG_COLOR = 'rgb(var(--color-primary, 17 17 17))';
export const DEFAULT_TEXT_SIZE = 48;
export const DEFAULT_TEXT_COLOR = '#ffffff';

/**
 * 아바타 형태
 * - 
 * @enum
 */
export enum AvatarShapeTypes {
  Square = 'square',
  Rounded = 'rounded',
  Circle = 'circle',
  Squircle = 'squircle',
}
