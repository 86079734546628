import * as AvatarPrimtives from '@radix-ui/react-avatar';
import { clsx } from 'clsx';
import { getFallbackString, getFallbackStringSize } from './utils';
import { DEFAULT_SIZE, DEFAULT_BG_COLOR, AvatarShapeTypes } from './constants';
import type { CommonProps } from './types';

export interface Props extends CommonProps {
  type?: Lowercase<keyof typeof AvatarShapeTypes>;
}

export function RadixAvatar({
  src,
  alt,
  size = DEFAULT_SIZE,
  type = AvatarShapeTypes.Square,
  backgroundColor = DEFAULT_BG_COLOR,
  className,
}: Props) {
  // squirecle 타입으로 잘못 들어올 경우 방어
  if (type === AvatarShapeTypes.Squircle) return;

  return (
    <AvatarPrimtives.Root
      className={clsx(
        'inline-flex items-center justify-center align-middle',
        'overflow-hidden select-none',
        'text-white', // 텍스트 색상은 고정시킴
        { 'rounded-2xl': type === AvatarShapeTypes.Rounded },
        { 'rounded-full': type === AvatarShapeTypes.Circle },
        className,
      )}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: getFallbackStringSize(size, type),
        backgroundColor,
      }}
    >
      <AvatarPrimtives.Image
        className="w-full h-full rounded-[inherit] object-cover"
        src={src}
        alt={alt}
      />
      <AvatarPrimtives.Fallback
        className={clsx(
          'flex h-full w-full items-center justify-center',
          'text-[3rem] leading-none align-middle',
          // 폰트의 하단에 마진이 발생하기 때문에 이에 대한 보정을 함
          'pt-[8px]',
        )}
      >
        {getFallbackString(alt)}
      </AvatarPrimtives.Fallback>
    </AvatarPrimtives.Root>
  );
}

export default RadixAvatar;
