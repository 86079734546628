import { useRef } from 'react';

export function useDebounceWithFlush<T extends unknown[]>(
  callback: (...params: T) => void,
  time: number,
): ((...params: T) => void) & { flush: () => void } {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedFunction = (...params: T) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      callback(...params);
      timer.current = null;
    }, time);
  };

  const flush = () => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
  };

  debouncedFunction.flush = flush;

  return debouncedFunction;
}

export default useDebounceWithFlush;
