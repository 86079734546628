import { useContext } from './Context';

export interface Props {
  id: string;
  value: string;
  label?: string;
  disabled?: boolean;
}

export function Item({ id, value, disabled = false }: Props) {
  const {
    checkedValue,
    name,
    disabled: radioDisabled,
    setValue,
  } = useContext();

  const isDisabled = disabled || radioDisabled;
  const isChecked = checkedValue === value;

  return (
    <input
      className="dop_radio"
      id={id}
      name={name}
      type="radio"
      value={value}
      checked={isChecked}
      disabled={isDisabled}
      onChange={() => setValue(value)}
      aria-checked={isChecked}
      aria-disabled={isDisabled}
    />
  );
}

export default Item;
