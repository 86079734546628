import { DaouofficeLogo } from './DaouofficeLogo';
import type { CommonProps } from './types';

/**
 * ServiceLogo 컴포넌트 속성
 */
export interface Props extends CommonProps {
  /** @property 서비스 타입 */
  type?: 'ep' | 'mp' | ServiceLogoType;
}

export enum ServiceLogoType {
  Employee = 'ep',
  Manage = 'mp',
}

/**
 * ServiceLogo 컴포넌트
 * @returns
 */
export function ServiceLogo({ type = 'ep', size = 'medium', ...props }: Props) {
  // 현재는 임직원 포탈 로고만 지원
  const Comp = type === 'ep' ? DaouofficeLogo : DaouofficeLogo;

  return <Comp size={size} {...props} />;
}

export default ServiceLogo;
