import * as DialogPrimitives from '@radix-ui/react-dialog';

import '@daouoffice/design/dist/foundation/dialog.css';

/**
 * Popup 컴포넌트 속성
 */
export interface Props extends DialogPrimitives.DialogCloseProps {
  /** @property 라벨 (aria-label 설정) */
  label?: string;
}

/**
 * Popup 컴포넌트
 * @returns
 */
export function Close({ children, label, ...props }: Props) {
  return (
    <DialogPrimitives.Close {...props} aria-label={label}>
      {children}
    </DialogPrimitives.Close>
  );
}

export default Close;
