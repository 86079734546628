import { CSSProperties, type PropsWithChildren } from 'react';
import * as SelectPrimitives from './primitives';
import { OptionInfo } from './types';

export type SelectType = 'system' | 'popup';

/**
 * Select 컴포넌트
 * @returns
 */

export interface Props {
  /** select 유형 (기본값: system) */
  selectType: SelectType;
  /** select trigger 태그 타입 (기본값: button) */
  withTitle?: boolean;
  /** select 옵션 값 리스트 (기본값: undefined) */
  defaultOption?: OptionInfo | undefined;
  /** select 옵션 값 리스트 (기본값: []) */
  options: OptionInfo[];
  /** tab-index 값 (기본값: 0) */
  tabIndex?: number;
  /** className */
  className?: string;
  /** CSS Style */
  style?: CSSProperties;
  /** 옵션 선택시 실행되는 함수 */
  onChangeValue?: (value: string) => void;
  /** aria-haspopup 속성. Variation 확장용 */
  ariaHasPopup?: boolean;
  /** aria-controls 속성. Variation 확장용 */
  ariaControls?: string;
  /** aria-expanded 속성. Variation 확장용 */
  ariaExpanded?: boolean;
}

export function Select({
  selectType = 'system',
  withTitle = true,
  defaultOption,
  options,
  tabIndex,
  className,
  onChangeValue,
  children,
  ...restProps
}: PropsWithChildren<Props>) {
  const contentTagType = selectType === 'popup' ? 'div' : 'select';
  const optionTagType = contentTagType === 'div' ? 'li' : 'option';

  return (
    <SelectPrimitives.Root
      {...restProps}
      type={selectType}
      as={'div'}
      defaultOption={defaultOption}
      options={options}
      tabIndex={tabIndex}
      className={className}
    >
      <SelectPrimitives.Trigger type={selectType} withTitle={withTitle}>
        {children}
      </SelectPrimitives.Trigger>
      <SelectPrimitives.Content
        tagType={contentTagType}
        data={options}
        onChangeValue={onChangeValue}
      >
        {(optionInfo) => (
          <SelectPrimitives.Option
            key={optionInfo.value}
            data={optionInfo}
            tagType={optionTagType}
          />
        )}
      </SelectPrimitives.Content>
    </SelectPrimitives.Root>
  );
}

export default Select;
