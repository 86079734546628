import * as DialogPrimitives from '@radix-ui/react-dialog';
import * as React from 'react';

import '@daouoffice/design/dist/foundation/dialog.css';

/**
 * Dialog.Root 컴포넌트 속성
 */
export interface Props {
  open?: boolean;
  modal?: boolean;
  onOpenChange?: (bool: boolean) => void;
}

/**
 * Dialog.Root 컴포넌트
 * @returns
 */
export function Root({
  children,
  open = false,
  onOpenChange = () => undefined,
}: React.PropsWithChildren<Props>) {
  const [openState, setOpenState] = React.useState(open);
  const changeHandler = (bool: boolean) => {
    onOpenChange(bool);
    setOpenState(bool);
  };

  React.useEffect(() => {
    setOpenState(open);
  }, [open]);

  return (
    <DialogPrimitives.Root open={openState} onOpenChange={changeHandler}>
      {children}
    </DialogPrimitives.Root>
  );
}

export default Root;
