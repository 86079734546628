import { PropsWithChildren } from 'react';
import { Provider } from './Context';
import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';

export type RadioAxis = 'vertical' | 'horizontal';

export interface Props {
  asChild?: boolean;
  className?: string;
  defaultValue?: string;
  disabled?: boolean;
  name: string;
  required?: boolean;
  axis?: RadioAxis;
  onValueChange?: (value: string) => void;
  /** aria-controls 속성. Variation 확장용 */
  ariaControls?: string;
}

export function Root({
  asChild = false,
  className,
  defaultValue,
  disabled = false,
  name,
  required = false,
  axis = 'horizontal',
  onValueChange,
  ariaControls,
  children,
}: PropsWithChildren<Props>) {
  const Comp = asChild ? Slot : 'div';

  return (
    <Provider
      name={name}
      defaultValue={defaultValue}
      disabled={disabled}
      onValueChange={onValueChange}
    >
      <Comp
        className={clsx('dop_radio', className, axis)}
        role="radiogroup"
        aria-disabled={disabled}
        aria-required={required}
        aria-controls={ariaControls ? ariaControls : undefined}
      >
        {children}
      </Comp>
    </Provider>
  );
}

export default Root;
