import { Slot } from '@radix-ui/react-slot';
import { OptionInfo } from '../types';

export type OptionTagTypes = 'li' | 'option';

export interface Props {
  data: OptionInfo;
  tagType: OptionTagTypes;
}

export function Option({ data, tagType }: Props) {
  const Wrapper = tagType === 'li' ? tagType : Slot;
  const Comp = tagType === 'li' ? 'a' : 'option';

  return (
    <Wrapper value={data.value}>
      {Comp === 'a' ? (
        <Comp href={data.value}>{data.title}</Comp>
      ) : (
        <Comp value={data.value}>{data.title}</Comp>
      )}
    </Wrapper>
  );
}

export default Option;
