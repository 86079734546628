import * as DialogPrimitives from '@radix-ui/react-dialog';

export { Root, type Props as RootProps } from './Root';
export { Close, type Props as CloseProps } from './Close';
export { Content, type Props as ContentProps } from './Content';

// re-export from @radix-ui/react-dialog
export const Trigger = DialogPrimitives.Trigger;
export type TriggerProps = DialogPrimitives.DialogTriggerProps;
export const Title = DialogPrimitives.Title;
export type TitleProps = DialogPrimitives.DialogTitleProps;
export const Description = DialogPrimitives.Description;
export type DescriptionProps = DialogPrimitives.DialogDescriptionProps;
