import { Slot } from '@radix-ui/react-slot';
import React, { CSSProperties, PropsWithChildren, createContext } from 'react';

/**
 * 버튼 태그 타입
 * @deprecated 삭제 예정.
 */
export type TagTypes = 'div' | 'button';

/**
 * ButtonPrimitives.Root > Props 컴포넌트 속성
 * @deprecated 삭제 예정.
 */
export interface Props {
  title: string;
  /** 버튼에 사용되는 태그이름 (기본값: 'div') */
  as?: TagTypes;
  asChild?: boolean;
  /** 버튼 비활성화 여부 (기본값: false) */
  disabled?: boolean;
  /** 버튼 토글 상태 (기본값: false) */
  pressed?: boolean;
  /** tab-index 값 (기본값: 0) */
  tabIndex?: number;
  /** aria-haspopup 속성. Variation 확장용 */
  ariaHasPopup?: boolean;
  /** aria-controls 속성. Variation 확장용 */
  ariaControls?: string;
  /** aria-expanded 속성. Variation 확장용 */
  ariaExpanded?: boolean;
  className?: string;
  style?: CSSProperties;

  /** @property 클릭 이벤트 핸들러 */
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
}

export interface IRootContext {
  title: string;
}

export const Context = createContext<IRootContext>({ title: '' });

/**
 * Button 컴포넌트
 * @deprecated 삭제 예정.
 * @returns
 */
export function Root({
  asChild = false,
  as = 'button',
  title,
  disabled = false,
  pressed = false,
  tabIndex = 0,
  ariaHasPopup,
  ariaControls,
  ariaExpanded,
  className,
  children,
  ...restProps
}: PropsWithChildren<Props>) {
  const Comp = asChild ? Slot : as;
  const rootCtxValues = {
    title,
  };

  return (
    <Context.Provider value={rootCtxValues}>
      <Comp
        title={title}
        className={className}
        disabled={disabled}
        tabIndex={tabIndex}
        role="button"
        aria-pressed={pressed === true ? true : undefined}
        aria-disabled={disabled === true ? true : undefined}
        aria-haspopup={ariaHasPopup ? 'true' : undefined}
        aria-controls={ariaControls ? ariaControls : undefined}
        aria-expanded={ariaExpanded ? 'true' : undefined}
        {...restProps}
      >
        {children}
      </Comp>
    </Context.Provider>
  );
}

export default Root;
