import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import { type CSSProperties, type PropsWithChildren } from 'react';
import { SelectType } from '../Select';
import { OptionInfo, TagTypes } from '../types';
import { Provider } from './Context';

import '@daouoffice/design/dist/foundation/select.css';

/**
 * SelectPrimitives.Root > Props 컴포넌트 속성
 */

export interface Props {
  type: SelectType;
  defaultOption: OptionInfo | undefined;
  options: OptionInfo[];
  /** 버튼에 사용되는 태그이름 (기본값: 'select') */
  as: TagTypes;
  /** tab-index 값 (기본값: 0) */
  tabIndex?: number;
  className?: string;
  style?: CSSProperties;
  /** aria-haspopup 속성. Variation 확장용 */
  ariaHasPopup?: boolean;
  /** aria-controls 속성. Variation 확장용 */
  ariaControls?: string;
  /** aria-expanded 속성. Variation 확장용 */
  ariaExpanded?: boolean;
}

/**
 * Select 컴포넌트
 * @returns
 */
export function Root({
  options,
  defaultOption,
  type,
  as,
  className,
  children,
  ariaHasPopup,
  ariaControls,
  ariaExpanded,
}: PropsWithChildren<Props>) {
  const Comp = as === 'div' ? as : Slot;

  return (
    <Comp
      className={clsx(
        {
          'flex relative': type === 'system',
        },
        className,
      )}
      aria-haspopup={ariaHasPopup ? 'true' : undefined}
      aria-controls={ariaControls ? ariaControls : undefined}
      aria-expanded={ariaExpanded ? 'true' : undefined}
    >
      <Provider defaultOptions={options} defaultSelectedOption={defaultOption}>
        {children}
      </Provider>
    </Comp>
  );
}

export default Root;
