import { clsx } from 'clsx';
import { PropsWithChildren } from 'react';
import * as ButtonPrimitives from './primitives';
import {
  ColorSetTypes,
  IconButtonSizeTypes,
  ShapeTypes,
  StyleTypes,
} from '@dop-ui/react/shared/ui/button/types';

/**
 * NewButton 컴포넌트 속성
 * @deprecated 삭제 예정.
 */
export interface IconButtonProps extends ButtonPrimitives.RootProps {
  //   /** 버튼 모양 (기본값: 'rect') */
  shape?: ShapeTypes;
  /** (미리 정해진) 버튼 크기 (기본값: 'medium') */
  size?: IconButtonSizeTypes;
  /** (미리 정해진) 버튼 색상 (기본값: 'primary') */
  colorset?: ColorSetTypes;
  /** (미리 정해진) 버튼 스타일 (기본값: 'solid') */
  styleType?: StyleTypes;
}

/**
 * NewButton 컴포넌트
 * @deprecated 삭제 예정.
 * @returns
 */
export function IconButton({
  shape = 'rect',
  size = 'medium',
  colorset = 'major',
  styleType = 'solid',
  title,
  className,
  children,
  ...restProps
}: PropsWithChildren<IconButtonProps>) {
  return (
    <ButtonPrimitives.Root
      className={clsx('btn_icon', size, shape, colorset, styleType, className)}
      title={title}
      {...restProps}
    >
      {children}
    </ButtonPrimitives.Root>
  );
}

export default IconButton;
