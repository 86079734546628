import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import type { PropsWithChildren } from 'react';

/**
 * ButtonPrimitive.Icon 컴포넌트 속성
 * @deprecated 삭제 예정.
 */
export interface Props {
  asChild?: boolean;
  className?: string;
}

/**
 * ButtonPrimitive.Icon 컴포넌트
 * @deprecated 삭제 예정.
 * @param props
 */
export function Icon({
  asChild = false,
  children,
  className,
}: PropsWithChildren<Props>) {
  const Comp = asChild ? Slot : 'span';

  return <Comp className={clsx('shrink-0', className)}>{children}</Comp>;
}

export default Icon;
