import type { PropsWithChildren } from 'react';
import * as ButtonPrimitives from '@dop-ui/react/shared/ui/button/primitives';
import {
  Button as DopButton,
  type Props as DopButtonProps,
} from '@dop-ui/react/shared/ui/button/dop-button';

// ButtonPrimitives의 주요 타입을 re-export
export type TagTypes = ButtonPrimitives.TagTypes;

/**
 * Button 컴포넌트 속성
 * @deprecated 삭제 예정.
 */
export interface Props extends DopButtonProps {
  /** 태그 타입 */
  tagType?: TagTypes;
  /** 라벨 클래스 네임 */
  labelClassName?: string;
  /** 라벨과 함께 쓸 것인지 여부 (기본 값 True) */
  withTitle?: boolean;
}

/**
 * Button 컴포넌트
 * @deprecated 삭제 예정.
 * @returns
 */
export function Button({
  tagType,
  children,
  variant = 'solid',
  shape = 'rect',
  colorset = 'level1',
  labelClassName = 'txt',
  title,
  withTitle = true,
  className,
  ...props
}: PropsWithChildren<Props>) {
  // 기존 클래스 명에 full을 추가하여 풀 사이즈 버튼을 만들었던 것에 대한 호환성 제공
  const isFullsize = hasClassName(className, 'full');

  return (
    <DopButton
      as={tagType}
      variant={variant}
      shape={shape}
      colorset={colorset}
      autosize={isFullsize ? 'full' : undefined}
      className={className}
      {...props}
    >
      {children}
      {title && withTitle && (
        <ButtonPrimitives.Label className={labelClassName}>
          {title}
        </ButtonPrimitives.Label>
      )}
    </DopButton>
  );
}

function hasClassName(className: string | undefined, keyword: string) {
  if (typeof className !== 'string') {
    return false;
  }

  // className을 공백으로 분리하여 배열로 변환
  const classNamesArray = className.split(' ');

  // 배열에 'full' 클래스가 있는지 여부를 확인
  return classNamesArray.includes(keyword);
}

export default Button;
