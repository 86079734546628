import { RadixAvatar } from './radix-avatar';
import { SquirecleAvatar } from './squirecle-avatar';
import { AvatarShapeTypes } from './constants';
import type { CommonProps } from './types';

export interface Props extends CommonProps {
  type?: Lowercase<keyof typeof AvatarShapeTypes>;
}

/**
 * Avatar 컴포넌트
 * @returns
 */
export function Avatar({ type = AvatarShapeTypes.Squircle, ...props }: Props) {
  const Comp =
    type === AvatarShapeTypes.Squircle ? SquirecleAvatar : RadixAvatar;
  const mergedProps = {
    ...props,
    type: type === AvatarShapeTypes.Squircle ? undefined : type,
  };

  return <Comp {...mergedProps} />;
}

export default Avatar;
