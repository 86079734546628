import { useEffect, useState } from 'react';

export interface Props {
  id: string;
  name: string;
  isChecked: boolean;
  isDisabled: boolean;
  onChange: (checked: boolean) => void;
  /** aria-checked 속성. Variation 확장용 */
  ariaChecked?: boolean;
  /** aria-disabled 속성. Variation 확장용 */
  ariaDisabled?: boolean;
  /** aria-required 속성. Variation 확장용 */
  ariaRequired?: boolean;
}

export function Item({
  id,
  name,
  isChecked: defaultChecked,
  isDisabled,
  ariaChecked,
  ariaDisabled,
  ariaRequired,
  onChange,
}: Props) {
  const [isChecked, setCheckState] = useState<boolean>(false);

  useEffect(() => {
    setCheckState(defaultChecked);
  }, [defaultChecked]);

  return (
    <input
      type="checkbox"
      name={name}
      id={id}
      checked={isChecked}
      disabled={isDisabled}
      onChange={(e) => {
        e.stopPropagation();
        setCheckState(e.target.checked);
        onChange(e.target.checked);
      }}
      aria-checked={ariaChecked ?? false}
      aria-disabled={ariaDisabled ?? false}
      aria-required={ariaRequired ?? false}
    />
  );
}

export default Item;
