/**
 * @deprecated Use `@dop-ui/react/shared/ui/button` instead.
 */
export { Button, default } from './Button';
export type { Props as ButtonProps, TagTypes } from './Button';

/**
 * @deprecated 삭제 예정
 */
export { IconButton, type IconButtonProps } from './IconButton';
