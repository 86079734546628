import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx';
import { Fragment, ReactNode } from 'react';
import { OptionInfo, TagTypes } from '../types';
import { useContext } from './Context';

export interface Props {
  data: OptionInfo[];
  tagType: TagTypes;
  children?: ReactNode | ((optionInfo: OptionInfo) => ReactNode);
  tabIndex?: number;
  onChangeValue?: (value: string) => void;
  className?: string;
}

export function Content({
  data,
  tagType,
  children,
  tabIndex = 0,
  onChangeValue,
  className,
}: Props) {
  const Wrapper = tagType === 'div' ? tagType : Slot;
  const Selecter = tagType === 'fragment' ? Slot : tagType;
  const Comp = tagType === 'div' ? 'ul' : Fragment;
  const renderChildren =
    typeof children === 'function' ? children : () => children;
  const { isOpened, selectedOption, setSelectedOptionState } = useContext();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement> | React.FormEvent<HTMLDivElement>,
  ) => {
    if (tagType === 'select') {
      const option = getOptionByValue(
        (e as React.ChangeEvent<HTMLSelectElement>).target.value,
      );
      if (option) {
        setSelectedOptionState(option);
        if (onChangeValue) onChangeValue(option.value);
      }
    }
  };

  const getOptionByValue = (value: string) => {
    return data.filter((option) => option.value === value)[0];
  };

  return (
    <>
      <Wrapper
        className={clsx(
          {
            wrap_select: tagType === 'div',
            open: isOpened,
          },
          className,
        )}
      >
        <Selecter
          value={selectedOption?.value}
          className={clsx(
            {
              hidden_select: tagType === 'select',
              select_list: tagType === 'div',
            },
            className,
          )}
          tabIndex={tabIndex}
          onChange={handleOnChange}
        >
          <Comp>{data.map(renderChildren)}</Comp>
        </Selecter>
      </Wrapper>
    </>
  );
}

export default Content;
